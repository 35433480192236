import React from "react"
import classNames from "classnames"

import DefaultLayout from "../../layout/default"

import ConsultationFormLarge from "../../components/_V3/SecretPages/ConsultationFormLarge"

import HeadingAndTextarea, {
	HEADING_TYPE,
} from "../../components/_V3/HeadingAndTextarea"

import secretImage from "../../pages-data/_V3/secret/index/ReadyProjects/assets/Secret.png"
import apply from "../../pages-data/_V3/secret/index/ReadyProjects/assets/apply.png"

import { requestForAutoOpenRestFaster } from "../../apiRequests/callToAction"

import {
	readyProjects,
	notReadyProjects,
	consultationForm,
} from "../../pages-data/_V3/secret/index/ReadyProjects/data"

import secretPageStyles from "../../pages-data/_V3/secret/index/ReadyProjects/style.module.scss"
import pageStyles from "../../styles/_V2/pagesStyles/styles.module.scss"
import styles from "../styles/_V2/secret.module.scss"

export default function Secret() {
	const metaTags = [
		{
			name: "description",
			content:
				"В каждом успешном ресторанном проекте, как и во вкусном блюде, есть свой секретный ингредиент. Мы решили его найти и рассказать в нашем медиапроекте «Секретный ингредиент»",
		},
		{
			property: "og:url",
			content: "https://quickresto.ru/secret/",
		},
		{
			property: "og:title",
			content:
				"«Секретный ингредиент» — медиапроект про практику и опыт пользователей Quick Resto",
		},
		{
			property: "og:description",
			content:
				"В каждом успешном ресторанном проекте, как и во вкусном блюде, есть свой секретный ингредиент. Мы решили его найти и рассказать в нашем медиапроекте «Секретный ингредиент»",
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content:
				"https://quickresto.ru/static/MediaMainBanner-2fae4648f998cecd8f012906a7a0f58e.png",
		},
	]

	const headingAndTextarea = {
		heading: (
			<span className={secretPageStyles.pageTitleWrapper}>
				Секретный ингредиент
				<img src={secretImage} className={secretPageStyles.secretImage} />
			</span>
		),
		headingType: HEADING_TYPE.PRIMARY,
		textarea: (
			<span className={secretPageStyles.pageTextareaWrapper}>
				В каждом успешном ресторанном проекте, как и во вкусном блюде, есть свой
				секретный ингредиент. Мы решили его найти и рассказать в нашем
				медиапроекте «Секретный ингредиент» — про практику и опыт пользователей
				Quick Resto.
			</span>
		),
	}

	return (
		<DefaultLayout
			title="«Секретный ингредиент» — медиапроект про практику и опыт пользователей Quick Resto"
			metaTags={metaTags}
			mainWrapperClassName={classNames(
				pageStyles.pageWrapper,
				pageStyles.pageGray,
				styles.bg
			)}
			linkCanonical="https://quickresto.ru/secret/"
		>
			<HeadingAndTextarea
				data={headingAndTextarea}
				className={styles.headingAndTextarea}
			/>

			<section
				className={classNames(
					secretPageStyles.container,
					!notReadyProjects.length ? secretPageStyles.containerWithMb : ""
				)}
			>
				<div className={secretPageStyles.blockTitleWrapper}>
					<h3 className={secretPageStyles.blockTitle}>Готово</h3>
					<p className={secretPageStyles.blockDesc}>
						Посмотри, что получилось у наших клиентов!
					</p>
				</div>
				<div className={secretPageStyles.projectsList}>
					{readyProjects.map(project => project)}
					<div className={styles.mediaContent}>
						<a
							href={"https://t.me/tsinelnikova"}
							rel="nofollow"
							target="_blank"
							className={styles.apply}
						>
							<img src={apply} loading="lazy" />
						</a>
					</div>
				</div>
			</section>

			{notReadyProjects.length ? (
				<section
					className={classNames(
						secretPageStyles.container,
						secretPageStyles.notReadyProjectList
					)}
				>
					<div className={secretPageStyles.blockTitleWrapper}>
						<h3 className={secretPageStyles.blockTitle}>Готовится</h3>
						<p className={secretPageStyles.blockDesc}>
							Зайди в YouTube и подпишись
						</p>
					</div>
					<div className={secretPageStyles.projectsList}>
						{notReadyProjects.map(project => project)}
					</div>
				</section>
			) : null}

			<ConsultationFormLarge
				data={consultationForm}
				className={classNames(
					pageStyles.pageSection,
					secretPageStyles.consultationFormLarge
				)}
				request={requestForAutoOpenRestFaster}
			/>
		</DefaultLayout>
	)
}
