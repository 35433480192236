import classNames from "classnames"
import React from "react"

import styles from "./styles.module.scss"

export enum HEADING_TYPE {
	PRIMARY = "primary",
	SECONDARY = "secondary",
}

interface Props {
	className?: string // Класс
	anchor?: string // Якорь
	data: {
		subheading?: string | JSX.Element // Доп заголовок синим цветом
		heading: string | JSX.Element // Заголовок
		headingType: HEADING_TYPE // Тип заголовока h1/h2
		textarea?: string | JSX.Element // Описание
		textareaClassName?: string
	}
}

// Компонент простого допзаголовка, заголовка и описания к нему
// Также к компоненту можно привязать якорь для использования в паре с компонентом 'HeadingTextareaAndAchors'
// Пример: /capability/, /automation/, /delivery-payment/ и /discounts/
export default function HeadingAndTextarea(props: Props) {
	const data = props.data || {}
	return (
		<section
			id={props.anchor}
			className={classNames(styles.section, props.className)}
		>
			{data.subheading && <span className={styles.subheading}>{data.subheading}</span>}

			{data.headingType === HEADING_TYPE.PRIMARY && (
				<h1 className={classNames(styles.heading, styles.headingPrimary)}>
					{data.heading}
				</h1>
			)}

			{data.headingType === HEADING_TYPE.SECONDARY && (
				<h2 className={classNames(styles.heading, styles.headingSecondary)}>
					{data.heading}
				</h2>
			)}

			{data.textarea && <p className={classNames(styles.textarea, data.textareaClassName)}>{data.textarea}</p>}
		</section>
	)
}
