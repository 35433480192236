import React from "react"

import TakeMyMoney from "../../../../../components/_V3/SecretPages/ConsultationFormLarge/assets/TakeMyMoney"
import { contacts } from "../../../../_V2/common/contacts"

import AdaptiveLink from "../../../../../components/_V2/AdaptiveLink"

import ReadyProjectOne from "./assets/ReadyProjectOne.webp"
import ReadyProjectTwo from "./assets/ReadyProjectTwo.webp"
import ReadyProjectThree from "./assets/ReadyProjectThree.webp"
import ReadyProjectFour from "./assets/ReadyProjectFour.webp"
import ReadyProjectFive from "./assets/ReadyProjectFive.webp"
import ReadyProjectSix from "./assets/ReadyProjectSix.png"
import ReadyProjectSeven from "./assets/ReadyProjectSeven.jpg"
import ReadyProjectEight from "./assets/ReadyProjectEight.png"
import ReadyProjectNine from "./assets/ReadyProjectNine.png"
import ReadyProjectTen from "./assets/ReadyProjectTen.png"

import kostya from "./assets/kostya.png"
import vladimir from "./assets/vladimir.png"
import nadezhda from "./assets/nadezhda.png"
import natalya from "./assets/natalya.png"
import evgeniya from "./assets/evgeniya.png"
import nastya from "./assets/nastya.png"
import andrey from "./assets/andrey.png"
import sasha from "./assets/sasha.png"
import inga from "./assets/inga.png"
import anton from "./assets/anton.png"
import maksim from "./assets/maksim.png"

import styles from "./style.module.scss"

export const readyProjects = [
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/molodo-zeleno/"
			className={styles.media}
		>
			<img src={ReadyProjectNine} alt="Кафе Молодо-Зелено" loading="lazy" />
			<img src={anton} className={styles.anton} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Открыть формат «лавка + кафе» было правильным решением
			</p>
			<p className={styles.readyProjectName}>Кафе «Молодо-зелено»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/virazh/"
			className={styles.media}
		>
			<img src={ReadyProjectTen} alt="Кофейня Вираж" loading="lazy" />
			<img src={maksim} className={styles.maksim} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Как без опыта превратить хобби в работающий бизнес
			</p>
			<p className={styles.readyProjectName}>Кафе «Вираж»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/park47/"
			className={styles.media}
		>
			<img src={ReadyProjectEight} alt="Кофейня «PARK47»" loading="lazy" />
			<img src={inga} className={styles.inga} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Как сделать спешелти-кофе популярным в спальном районе
			</p>
			<p className={styles.readyProjectName}>Кофейня «PARK47»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/kroshka-li/"
			className={styles.media}
		>
			<img src={ReadyProjectSeven} alt="Фудтрак Крошка Ли" loading="lazy" />
			<img src={vladimir} className={styles.vladimir} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Фудтрак как безопасный вариант бизнеса: опыт петербургского шефа
			</p>
			<p className={styles.readyProjectName}>Фудтрак «Крошка Ли»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/zolotoj-zhuk/"
			className={styles.media}
		>
			<img src={ReadyProjectSix} alt="Чайная Золотой Жук" loading="lazy" />
			<img src={sasha} className={styles.sasha} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Как интерес к китайскому чаю перерос в успешный фуд-проект с оборотом 2
				млн рублей...
			</p>
			<p className={styles.readyProjectName}>Кальянная «Золотой Жук»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/sezony/"
			className={styles.media}
		>
			<img src={ReadyProjectFive} alt="Бар «Сезоны»" loading="lazy" />
			<img className={styles.kostya} src={kostya} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				«Открывать очередной ресторан à la carte нам было неинтересно». История
				частного бара для камерных мероприятий
			</p>
			<p className={styles.readyProjectName}>Бар «Сезоны»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/dobrocoffee/"
			className={styles.media}
		>
			<img
				src={ReadyProjectFour}
				alt="Кофейня «DO.BRO Coffee»"
				loading="lazy"
			/>
			<img className={styles.vladimir} src={vladimir} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Как спортсмен с Дальнего Востока создал сеть кофеен по всей России
			</p>
			<p className={styles.readyProjectName}>Кофейня «DO.BRO Coffee»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/pervyj-nos/"
			className={styles.media}
		>
			<img src={ReadyProjectThree} alt="Бар «Первый нос»" loading="lazy" />
			<img src={nadezhda} className={styles.nadezhda} />
			<img src={natalya} className={styles.natalya} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Как из небольшой кофейни вырасти в сеть винотек и баров
			</p>
			<p className={styles.readyProjectName}>Бар «Первый нос»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/shchegol/"
			className={styles.media}
		>
			<img src={ReadyProjectTwo} alt="Кофейня «Щегол»" loading="lazy" />
			<img src={evgeniya} className={styles.evgeniya} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Как ежегодно увеличивать прибыль на 20%
			</p>
			<p className={styles.readyProjectName}>Кофейня «Щегол»</p>
		</div>
	</div>,
	<div className={styles.mediaContent}>
		<AdaptiveLink
			useGatsbyLink={true}
			href="/secret/ohmybowl/"
			className={styles.media}
		>
			<img src={ReadyProjectOne} alt="Кафе «О, мой боул!»" loading="lazy" />
			<img src={nastya} className={styles.nastya} />
			<img src={andrey} className={styles.andrey} />
		</AdaptiveLink>
		<div className={styles.readyProjectContent}>
			<p className={styles.readyProjectDesc}>
				Остаться без шефа за день до открытия и сделать рекордную кассу за месяц
			</p>
			<p className={styles.readyProjectName}>Кафе «О, мой боул!»</p>
		</div>
	</div>,
]

export const notReadyProjects = [
	// <div className={styles.mediaContent}>
	// 	<div className={styles.media}>
	// 		<div className={styles.tag}>
	// 			<ClockIcon />
	// 			<span>Cкоро</span>
	// 		</div>
	// 		<img src={NotReadyProjectTwo} alt="Кафе «Вираж»" loading="lazy" />
	// 	</div>
	// 	<div
	// 		className={classNames(
	// 			styles.readyProjectContent,
	// 			styles.notReadyProjectDesc
	// 		)}
	// 	>
	// 		<p className={styles.readyProjectDesc}>
	// 			«А почему не собирать велосипедистов в собственном кафе?». Кейс о том,
	// 			как без опыта превратить хобби в работающий бизнес
	// 		</p>
	// 		<p className={styles.readyProjectName}>Кафе «Вираж»</p>
	// 	</div>
	// </div>
]

export const consultationForm = {
	icon: <TakeMyMoney className={styles.icon} />,
	subheading: "время принять решение",
	heading: "Хочу открыть свой бизнес!",
	phone: contacts.phone,
	btn: "Заказать звонок",
}
