export default {
	inputTitle: "Ваш телефон",
	modal: {
		title: "Консультация",
		details:
			"Оставь номер для связи, мы перезвоним и расскажем, как настроить Quick Resto для бизнеса.",
		action: "Оставить заявку",
		successTitle: "Отлично!",
		successDetails: "Наш менеджер перезвонит вам",
		successAction: "Хорошо",
	},
}
