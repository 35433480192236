import React, { Dispatch, SetStateAction } from "react"

import { RequestCallModal } from "../../../components/_V2/RequestCallModal/RequestCallModal"

import data from "./data"

interface Props {
	className?: string
	isOpen: boolean
	onClose: Dispatch<SetStateAction<boolean>>
	request?: Function
}

export default function ConsultationCtaFormA(props: Props) {
	return (
		<>
			<RequestCallModal
				isOpen={props.isOpen}
				onClose={props.onClose}
				modalData={data.modal}
				inputTitle={data.inputTitle}
				showBackdropShape={true}
				request={props.request}
			/>
		</>
	)
}
