import React, { useState } from "react"
import classNames from "classnames"

import PhoneBackdrop from "./assets/PhoneBackdrop"

import ConsultationCtaFormA from "../../../_V2/ConsultationCtaFormA"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	data: {
		icon: JSX.Element
		subheading: string | JSX.Element
		heading: string | JSX.Element
		phone: {
			value: string
			text: string
		}
		btn: string
	}
	request?: Function
}

export default function ConsultationForm(props: Props) {
	const data = props.data || {}

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<>
			<section className={classNames(styles.section, props.className)}>
				<div className={styles.banner}>
					<div className={styles.banner__row}>
						<div className={styles.banner__column}>
							<div className={styles.textContent}>
								<p className={styles.textContent__subheading}>
									{data.subheading}
								</p>
								<div className={styles.textContent__bottom}>
									{data.icon}
									<h2 className={styles.textContent__heading}>
										{data.heading}
									</h2>
								</div>
							</div>
						</div>
						<div className={styles.banner__column}>
							<a className={styles.phone} href={`tel:${data.phone.value}`}>
								<PhoneBackdrop className={styles.phone__backdrop} />
								<span className={styles.phone__number}>{data.phone.text}</span>
							</a>
						</div>
						<div className={styles.banner__column}>
							<a
								className={styles.btn}
								onClick={handleModalStatus}
								href={"#popup"}
							>
								{data.btn}
							</a>
						</div>
					</div>
				</div>
			</section>

			<ConsultationCtaFormA
				isOpen={isModalOpen}
				onClose={handleModalStatus}
				request={props.request}
			/>
		</>
	)
}
