import * as React from "react"

const TakeMyMoney = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="120"
		height="100"
		viewBox="0 0 120 100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M23.0605 21.3604C13.1605 26.5104 5.68046 35.5404 2.53046 46.3104C1.16046 50.9504 0.600459 55.9304 0.990459 61.0404C1.55046 68.6704 4.18046 75.6704 8.28046 81.5004C16.3205 93.0904 30.1305 100.19 45.2305 99.0603C47.4705 98.8903 49.6605 98.5503 51.7905 98.0303C56.9705 96.7903 61.7705 94.5704 65.9505 91.5704C67.4505 90.5004 68.8605 89.3303 70.2005 88.0903H70.2205C71.4605 86.9303 72.6205 85.6904 73.7105 84.3904"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M62.9395 23.0703C66.4695 25.1603 69.6795 27.7903 72.4595 30.8503C77.2495 36.1103 80.7395 42.7203 82.2895 50.2203C82.5195 51.3503 82.6995 52.4703 82.8295 53.6003"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M51.3096 53.2402C53.7096 52.3902 55.7796 50.7202 56.9296 48.4302C58.1496 45.9902 58.2496 43.1702 58.4896 40.4102"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M35.8096 42.1698C34.0796 41.5498 32.6496 40.0298 32.3296 38.2198C32.0096 36.3998 32.9296 34.3698 34.5996 33.5998C36.2696 32.8098 38.5496 33.5198 39.3296 35.1798C41.2296 32.0798 40.8696 27.7998 38.5596 24.9798C39.2596 25.5398 40.4796 25.4098 41.1296 24.7198C41.8396 23.9098 41.8796 22.6698 41.5096 21.6598C41.1396 20.6498 40.3996 19.7998 39.7096 19.0098C40.8896 20.3798 45.0996 20.9298 46.8496 21.2498C49.3896 21.7398 51.9796 22.2998 54.5896 22.2598C57.6096 22.1998 59.5296 21.1498 59.5296 21.1498C61.2996 23.2898 60.9396 27.3498 60.6596 29.1898C60.3196 31.4098 59.6796 33.5498 59.1796 35.7098"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M54.8604 40.2099C55.8904 41.0099 57.3904 41.0499 58.4804 40.3999C58.6604 40.2999 58.8104 40.1699 58.9704 40.0299C60.0604 38.9999 60.2404 37.1499 59.3804 35.9399C59.3204 35.8599 59.2603 35.7799 59.1903 35.7099C58.3903 34.7399 57.0704 34.2899 55.8204 33.9199"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M57.0498 28.9096C58.5798 28.6896 59.9898 27.7296 60.7498 26.3896"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M52.2996 28.8301C50.7696 28.6501 49.2996 28.0401 48.0996 27.0801"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M54.4099 46.0503C53.9399 46.9203 53.2499 47.7103 52.3499 48.1203C50.8299 48.8203 48.9099 48.2803 47.7599 47.0503C46.6099 45.8203 46.1999 44.0403 46.3699 42.3703C46.3899 42.1603 46.4299 41.9303 46.5799 41.7803L54.8599 43.0403C55.0399 43.5603 54.6499 45.5803 54.3999 46.0503H54.4099Z"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M59.5695 21.2205C61.0295 20.5905 61.9895 19.1005 62.2995 17.5405C62.6095 15.9805 62.3495 14.3605 61.9395 12.8305C60.7495 8.38047 58.2395 4.30047 54.8095 1.23047C54.9195 3.30047 53.8195 5.39047 52.0495 6.47047C51.3595 4.09047 49.0695 2.26047 46.5995 2.11047C47.7295 3.47047 47.4195 5.54047 46.6595 7.14047C46.1895 8.12047 45.5295 9.06047 44.5695 9.55047C43.1095 10.2905 41.3695 9.83047 39.7395 9.82047C35.9795 9.81047 32.3895 12.6305 31.5095 16.2905C31.5295 15.5905 31.5395 14.8405 31.1595 14.2505C30.7795 13.6605 29.8695 13.3605 29.3695 13.8405C29.1095 14.0905 29.0295 14.4705 28.9895 14.8305C28.8895 15.8705 29.0995 16.9305 29.5695 17.8605C28.7395 16.7905 27.3995 15.6905 26.1595 16.2605C25.0895 16.7605 24.8995 18.3005 25.4595 19.3405C26.0195 20.3805 27.0995 21.0205 28.1195 21.6105C26.0695 26.8105 27.8495 33.2705 32.2895 36.6705"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M35.8203 42.1797C35.8003 44.8497 35.3003 47.7997 34.3203 50.2897"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M43.79 55.4999C45.83 59.2799 47.1 63.4699 47.51 67.7399C47.53 67.6599 47.52 67.5999 47.57 67.5299C50.37 63.3599 52.92 59.0199 55.22 54.5599C54.4 53.9299 53.58 53.2999 52.76 52.6699"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M35.1695 46.7096C34.2495 46.6796 33.3195 46.7396 32.4095 46.8896C31.7695 46.9996 31.0295 47.2497 30.8795 47.8797C30.7295 48.5297 31.3095 49.0996 31.8495 49.4996C35.4095 52.1396 39.5395 54.0596 43.7395 55.4796"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M51.5498 61.2198C52.9398 61.6498 54.2398 62.3898 55.3098 63.3798C55.5798 60.4998 55.3998 57.4298 55.2298 54.5498"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M43.7403 55.9596C43.0303 58.8996 42.8503 61.9696 43.2103 64.9796C40.5403 64.1296 37.9003 63.1496 35.4503 61.7896C33.0003 60.4296 30.7403 58.6796 29.0003 56.4796C28.6203 55.9996 28.2703 55.4896 28.1103 54.8996C27.9803 54.3796 28.0103 53.8396 28.0903 53.3096C28.4003 51.0896 29.5403 49.2996 31.0103 47.5996"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.40039 64.5997C7.75039 56.4697 17.9904 51.3897 28.3404 51.6597"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M94.6703 74.7305C89.3003 78.9205 82.8303 80.8205 76.0903 81.2505C70.2703 81.6605 64.2203 80.9905 58.4303 79.8405C57.2303 85.1605 55.9903 90.5505 53.4303 95.3605C52.9403 96.2805 52.4003 97.1605 51.7803 98.0305"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M89.4899 56.0405L72.9699 60.7905L55.6299 55.9805"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M89.7598 56.3796C90.6198 55.3096 92.5798 53.8796 92.5798 53.8796C92.5798 53.8796 91.6198 51.0096 91.8598 49.4196C92.0698 48.0196 92.8298 45.8096 95.0998 44.8196C96.4098 44.4896 97.7498 45.3896 99.6798 45.7096C103.01 46.2596 103.9 47.1896 104.11 48.7096C104.02 51.1696 99.5298 50.6196 97.2698 48.9396"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M92.5996 53.9404C93.3696 55.3904 94.4596 56.6804 95.7696 57.6704"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M91.1299 69.3197C97.3099 68.9297 104.5 67.0597 109.72 63.7197"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M106.16 46.6801C105.05 47.7601 105.62 49.7901 106.7 50.2501C107.28 50.5001 107.75 50.5301 108.38 50.4501C109.01 50.3701 109.53 50.2401 110.11 49.9701C109.35 50.5301 108.66 51.0301 108.21 51.8601C107.76 52.6901 107.67 53.7401 108.12 54.5801C108.76 55.7601 110.54 55.2901 110.63 55.2401C110.63 55.2401 109.12 55.8201 109.47 57.1201C109.68 57.8901 110.33 58.5001 111.1 58.7201C111.87 58.9401 112.7 58.8101 113.46 58.5401C114.66 58.1101 115.92 57.3801 116.05 56.1101C116.19 54.8101 115.97 54.5301 115 53.2301C115 53.2301 115.91 52.1601 115.58 50.2801C115.44 49.5001 114.72 48.0801 113.95 47.9101C115.27 47.0601 115.03 44.8501 114.44 43.9601C113.69 42.8301 111.89 42.1101 110.62 43.0301L106.17 46.6901L106.16 46.6801Z"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M114.1 47.8496L109.71 50.1296"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M115.08 53.1104C113.5 53.8104 112.05 54.6004 110.47 55.2904"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.63019 83.3202C9.50019 78.7902 10.1202 74.2502 11.4702 69.9102"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M95.4801 68.85C95.5601 69.81 95.4801 71.93 95.3901 72.95C95.3501 73.42 95.1501 74.64 94.6801 74.73C94.1901 74.84 92.5801 73.73 91.6001 71.14C89.3801 65.28 89.2301 62.29 89.5001 56.04"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M51.3097 53.21C49.6997 53.7 48.0997 53.71 46.4697 53.3"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M51.3402 53.5498L49.9102 60.0198"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M111.78 58.8198C112.46 60.5298 113.25 62.0698 114.15 63.4398L109.72 63.7198L99.8196 64.3198C100.01 59.4898 99.8796 54.7198 99.3896 50.0098"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M98.8197 45.5202C98.0697 40.3502 96.8697 33.8102 95.1797 28.8302C101.72 28.3302 106.77 29.2302 110.43 30.3202C109.57 34.9602 109.17 39.9002 109.19 43.9802"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M110.61 43.0203C111.13 40.1703 112.38 37.6403 115.41 34.1203C115.41 34.1203 111.91 32.7203 110.13 32.2803"
			stroke="#2A2D33"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M58.1247 31.5881C58.2112 30.8475 57.8989 30.2025 57.4271 30.1475C56.9553 30.0924 56.5028 30.6481 56.4163 31.3886C56.3299 32.1292 56.6422 32.7741 57.114 32.8292C57.5857 32.8843 58.0383 32.3286 58.1247 31.5881Z"
			fill="#2A2D33"
		/>
		<path
			d="M50.8552 31.494C50.9462 30.7151 50.6152 30.0364 50.116 29.9781C49.6169 29.9198 49.1385 30.504 49.0475 31.2829C48.9565 32.0619 49.2875 32.7406 49.7867 32.7989C50.2859 32.8572 50.7643 32.273 50.8552 31.494Z"
			fill="#2A2D33"
		/>
	</svg>
)

export default TakeMyMoney
